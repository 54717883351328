<template>
    <div>
        <div class="text-center p-t-10">
          <div @click="handleChangeTag(item)" v-for="(item,index) in tagArray" :key="index" :class="currentTag=== item.id?'workplatform-radio-button-active':'workplatform-radio-button'" class="p-l-20 p-r-20">{{item.name}}</div>
          <!-- <div @click="handleChangeTag(2)" :class="currentTag=== 2?'workplatform-radio-button-active':'workplatform-radio-button'" class="p-l-20 p-r-20">合同甲方</div> -->
        </div>

        <component :is="currentComponent + 'Component'"/>
    </div>
</template>

<script>
export default {
  components: {
    // WorkFlow
    createComponent: () => import('@/components/setting/workflow/Create')
  },
  data () {
    return {
      tagArray: [
        { id: 1, name: '审批流程设置', typeKey: 'workflow' }
      ],
      currentTag: 1,
      currentComponent: 'create'
    }
  },
  methods: {
    handleChangeTag (tag) {
      this.currentTag = tag.id
      this.$store.commit('set_setting_settingType', tag.typeKey)
      this.$store.commit('set_setting_detailId', 0)
      this.$store.commit('set_setting_pageType', 'default')
      switch (this.currentTag) {
        case 1:
          this.currentComponent = 'create'
          break
        default:
          break
      }
    }
  }
}
</script>
